//Do Not Update this file: the contents of this file are generated via a script
 export const defaultMarkup = (theme:string | null)=>`<footer id="footer" role="contentinfo">
    <div class="footer">
        <!-- Social links -->
        <section class="footer-social-section">
            <a id="carmax-logo-footer" href="/" class="footer-link mobile-hidden tablet-hidden" aria-label="CarMax home page">
                <!-- CarMax logo -->
                <svg
                    height="24"
                    width="123"
                    viewBox="0 0 327.84 64"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path
                        fill="#fff"
                        d="M289.8 5.05a1.1 1.1 0 0 0 .85-1.12c0-1.17-1.23-1.17-1.57-1.17h-1.65V7.4h.64V5.3h.8c.76 0 .94.2 1 .9a5.26 5.26 0 0 0 .23 1.2h.66a6.74 6.74 0 0 1-.28-1.46c-.05-.57-.2-.77-.7-.9zm-.72-.3h-1V3.3h1c.34 0 .92 0 .92.74s-.6.7-.92.7zM289 .8a4.33 4.33 0 1 0 4.32 4.32A4.34 4.34 0 0 0 289 .8zm0 8.2a3.83 3.83 0 1 1 3.8-3.84A3.82 3.82 0 0 1 289 9z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M129.42 54.55h32.32V64h-32.32v-9.45zm41.53 0V64h32.32v-9.45zm41.52 0V64h32.32v-9.45zm41.52 0V64h32.3v-9.45zm41.52 0V64h32.32v-9.45z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M129.57 42.43V1.23h16.26l.4 6.24h.15c2.82-5.9 7-7.47 10.88-7.47 5.3 0 9.16 2.38 11.3 7.47 2.82-5.9 7-7.47 10.88-7.47 7 0 12 4.1 12 15v27.43h-16.7V16.58c0-2.87-1.3-4.43-2.88-4.43s-3 1.3-3 4.6v25.68h-16.7V16.58c0-2.87-1.3-4.43-2.9-4.43s-3 1.3-3 4.6v25.68zm138.26 0h16.75l-11.48-22 9.35-19H265l-4.64 13-5.6-13H237.3l10.16 19-11.15 22h16.95l7.1-15 7.48 15zm-48.7-14.2V24.8h-2.6c-5.1 0-6.2 2.46-6.2 4.74 0 2.62 1.5 4.25 4.44 4.25 3.34 0 4.37-2.2 4.37-5.56zm0-11.32v-4.23c0-2-1-3.27-3.72-3.27-2.37 0-3.65.82-3.88 4.25h-15.86c.64-11 10.5-13.4 20.57-13.4 15.33 0 20.25 5 20.25 14.54v27.6h-16.85l-.5-3.88c-2.78 3.76-7 5.15-11.36 5.15-8.1 0-13.8-4.4-13.8-12.5 0-10.12 7.93-14.3 22.86-14.3z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M25.18 27.16H40.5c0 11.38-9.1 16.35-19.32 16.35C6.7 43.5 0 34.7 0 21.6-.38 8.75 7.28.06 21.23.06c10.4 0 19.22 4.23 19.28 16.36h-15c-.22-4.68-1.93-5.6-3.93-5.6-4.24 0-4.64 4.35-4.64 11 0 6.3.35 11 4.46 11 2.1 0 3.54-1.26 3.77-5.6zm78.18-8.77h1.37c3.15 0 5.43-1.1 5.43-4.18s-2.1-3.66-5.3-3.66h-1.5v7.83zm-16 24V1.2h24.4c8.84 0 14 2.68 14 10.45 0 4.74-2 8.34-8.18 9.94 4 .33 6.17 2.1 7 5.24.9 3.54 1.3 8.08 2.07 15.6h-16c-1.5-9.25-.88-8.3-1.45-11.83-.35-2.05-1.37-2.85-4-2.85h-1.9v14.68zM58.53 25.72h7l-3.4-15.55-3.6 15.55zm-19.8 16.7L52 1.13h21.17l13.27 41.3H69.5l-1.6-6.64H56.08l-1.66 6.63z"
                    ></path>
                </svg>
            </a>
            <a href="https://www.youtube.com/&#64;CarMax" target="_blank" rel="noopener" class="footer-link footer-link--social" aria-label="CarMax Youtube">
                <!-- YouTube icon -->
                <svg
                    width="30"
                    height="21"
                    viewBox="0 0 30 21"
                    fill="none"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path d="M28.69 3.17C28.36 1.94 27.39 0.98 26.16 0.65C23.93 0.05 15 0 15 0C15 0 6.07 0 3.84 0.65C2.61 0.98 1.64 1.94 1.31 3.17C0.71 5.40 0.71 10.05 0.71 10.05C0.71 10.05 0.71 14.70 1.31 16.93C1.64 18.16 2.61 19.12 3.84 19.45C6.07 20.05 15 20.05 15 20.05C15 20.05 23.93 20.05 26.16 19.45C27.39 19.12 28.36 18.16 28.69 16.93C29.28 14.70 29.28 10.05 29.28 10.05C29.28 10.05 29.28 5.40 28.69 3.17H28.69ZM12.14 14.34V5.77L19.56 10.05L12.14 14.34Z" fill="white" />
                </svg>
            </a>
            <a href="https://instagram.com/carmax?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener" class="footer-link footer-link--social" aria-label="CarMax Instagram">
                <!-- Instagram icon -->
                <svg
                    width="20" 
                    height="21"
                    fill="none"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path fill="#fff" d="M5.858.121c-1.064.05-1.79.22-2.425.47-.658.256-1.215.6-1.77 1.156a4.898 4.898 0 0 0-1.15 1.772C.267 4.156.1 4.883.053 5.948.006 7.012-.004 7.355.001 10.07c.005 2.716.017 3.056.069 4.123.05 1.064.22 1.79.47 2.426.256.657.6 1.214 1.156 1.769a4.894 4.894 0 0 0 1.774 1.15c.636.245 1.363.413 2.428.46 1.064.046 1.407.057 4.122.052 2.715-.005 3.056-.017 4.123-.068 1.066-.05 1.79-.221 2.425-.47a4.901 4.901 0 0 0 1.769-1.156 4.9 4.9 0 0 0 1.15-1.774c.246-.636.413-1.363.46-2.427.046-1.067.057-1.408.052-4.123-.005-2.715-.018-3.056-.068-4.122-.05-1.067-.22-1.79-.47-2.427a4.91 4.91 0 0 0-1.156-1.769 4.88 4.88 0 0 0-1.773-1.15c-.637-.245-1.364-.413-2.428-.46C13.039.06 12.697.048 9.98.053 7.265.057 6.925.07 5.858.121Zm.117 18.078c-.975-.043-1.504-.205-1.857-.34-.467-.18-.8-.398-1.152-.746a3.08 3.08 0 0 1-.75-1.149c-.137-.352-.302-.881-.347-1.856-.05-1.054-.06-1.37-.066-4.04-.006-2.67.004-2.986.05-4.04.042-.974.205-1.504.34-1.857.18-.468.397-.8.746-1.151a3.087 3.087 0 0 1 1.149-.75c.352-.138.881-.302 1.856-.348 1.054-.05 1.37-.06 4.04-.066 2.67-.006 2.986.004 4.041.05.974.043 1.505.204 1.857.34.467.18.8.397 1.151.746.351.35.568.682.75 1.15.138.35.302.88.348 1.855.05 1.054.061 1.37.066 4.04.005 2.669-.004 2.986-.05 4.04-.043.975-.205 1.504-.34 1.857a3.1 3.1 0 0 1-.747 1.152c-.349.35-.681.567-1.148.75-.352.137-.882.301-1.856.347-1.054.05-1.37.06-4.04.066-2.671.006-2.986-.005-4.04-.05m8.151-13.493a1.2 1.2 0 1 0 2.4-.003 1.2 1.2 0 0 0-2.4.003Zm-9.262 5.355a5.134 5.134 0 1 0 10.27-.02 5.134 5.134 0 0 0-10.27.02Zm1.802-.004a3.333 3.333 0 1 1 6.666-.013 3.333 3.333 0 0 1-6.666.013Z" />
                </svg>
            </a>
            <a href="https://www.tiktok.com/&#64;carmax" target="_blank" rel="noopener" class="footer-link footer-link--social" aria-label="CarMax TikTok">
                <!-- TikTok icon -->
                <svg
                    width="18"
                    height="21"
                    fill="none"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path fill="#fff" d="M15.116 4.06A4.793 4.793 0 0 1 12.936.05H9.499l-.005 13.78a2.892 2.892 0 0 1-2.885 2.782 2.891 2.891 0 0 1-2.89-2.89 2.894 2.894 0 0 1 2.89-2.891c.297 0 .583.049.853.133v-3.51a6.297 6.297 0 0 0-.853-.062 6.336 6.336 0 0 0-6.329 6.33 6.324 6.324 0 0 0 2.702 5.181 6.29 6.29 0 0 0 3.627 1.147 6.336 6.336 0 0 0 6.329-6.328V6.734a8.18 8.18 0 0 0 4.784 1.538V4.834c-.96 0-1.855-.285-2.605-.774Z" />
                </svg>
            </a>
            <a href="https://www.facebook.com/CarMax/" target="_blank" rel="noopener" class="footer-link footer-link--social" aria-label="CarMax Facebook">
                <!-- Facebook icon -->
                <svg
                    width="24"
                    height="23" 
                    fill="none"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path fill="#fff" d="M23.067 11.118C23.067 5.006 18.112.051 12 .051S.933 5.006.933 11.118c0 5.524 4.047 10.103 9.338 10.933v-7.734H7.46v-3.199h2.81V8.68c0-2.774 1.652-4.306 4.18-4.306 1.211 0 2.477.216 2.477.216v2.724h-1.395c-1.375 0-1.804.853-1.804 1.728v2.076h3.07l-.49 3.2h-2.58v7.733c5.291-.83 9.338-5.409 9.338-10.933Z" />
                </svg>
            </a>
        </section>
        <!-- My store flyout -->
        <section class="footer-my-store-section">
            <button
                id="footer-my-store-button"
                aria-haspopup="true"
                aria-controls="footer-my-store-menu"
                aria-label="Open my store flyout"
                class="header-footer-icon-button"
            >
                <!-- Location icon with alert -->
                <svg
                    class="header-footer-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29 11c0-5.35 4.36-9.71 9.71-9.71 5.35 0 9.71 4.12 9.71 9.71 0 5.569-6.153 9.57-8.207 10.905l-.023.015c-.45.29-.97.44-1.48.44s-1.03-.15-1.48-.44C8.48 20.6 2.29 16.35 2.29 11Zm8.97 9.711.03.019c.43.28.99.28 1.42 0 1.87-1.21 7.58-4.91 7.58-9.73 0-4.57-3.72-8.29-8.29-8.29-4.57 0-8.29 3.48-8.29 8.29 0 4.785 5.651 8.472 7.55 9.711ZM8.29 11c0-2.05 1.66-3.71 3.71-3.71s3.71 1.66 3.71 3.71-1.66 3.71-3.71 3.71S8.29 13.05 8.29 11Zm1.42 0c0 1.26 1.03 2.29 2.29 2.29s2.29-1.03 2.29-2.29S13.26 8.71 12 8.71 9.71 9.74 9.71 11Z" />
                    <circle
                        class="hidden header-footer-location-icon-alert-circle"
                        cx="19"
                        cy="4"
                        r="4"
                        stroke="#1773cf"
                        stroke-width="1"
                    />
                    <circle
                        class="hidden header-footer-location-icon-alert-circle"
                        cx="19"
                        cy="4"
                        r="3"
                        stroke="white"
                        stroke-width="1"
                    />
                </svg>
                <span class="visually-hidden">My store name</span>
                <span id="footer-my-store-button-text">Find Your Store</span>
                <!-- Arrow drop down icon -->
                <svg
                    class="header-footer-icon"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                >
                    <path d="M7 10l5 5 5-5z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
            </button>
            <div class="header-footer-menu-container">
                <div
                    id="footer-my-store-menu"
                    aria-modal="true"
                    role="dialog"
                    aria-labelledby="footer-my-store-button"
                    class="header-footer-menu"
                    tabindex="-1"
                ></div>
            </div>
        </section>
        <div class="footer-section-divider"></div>
        <!-- Primary navigation -->
        <div class="footer-primary-navigation">
            <section class="footer-primary-navigation-section">
                <h2 class="footer-primary-navigation-section-header">Shop</h2>
                <ul class="footer-primary-navigation-section-list">
                    <li class="footer-primary-navigation-section-list-item"><a href="/cars" class="footer-link" data-kmx-analytics="searchInit">Browse by category</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/cars/all" class="footer-link" data-kmx-analytics="searchInit">View all inventory</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/stores" class="footer-link">Find a store</a></li>
                </ul>
            </section>
            <section class="footer-primary-navigation-section">
                <h2 class="footer-primary-navigation-section-header">Sell/Trade</h2>
                <ul class="footer-primary-navigation-section-list">
                    <li class="footer-primary-navigation-section-list-item"><a href="/sell-my-car" class="footer-link">Get an online offer</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/sell-my-car#how-it-works" class="footer-link">How it works</a></li>
                </ul>
            </section>
            <section class="footer-primary-navigation-section">
                <h2 class="footer-primary-navigation-section-header">Finance</h2>
                <ul class="footer-primary-navigation-section-list">
                    <li class="footer-primary-navigation-section-list-item"><a
                            id="footer-link-prequalify"
                            href="/pre-qual/app?op=global_footer"
                            class="footer-link"
                            rel="nofollow"
                            data-finance-op="global_footer"
                            >Get pre-qualified</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/car-financing" class="footer-link">How it works</a>
                    </li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/car-financing/carmax-auto-finance"
                            class="footer-link">CarMax Auto Finance</a></li>
                </ul>
            </section>
            <section class="footer-primary-navigation-section">
                <h2 class="footer-primary-navigation-section-header">About</h2>
                <ul class="footer-primary-navigation-section-list">
                    <li class="footer-primary-navigation-section-list-item"><a href="/about-carmax" class="footer-link">About CarMax</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/help-center#contact-us" class="footer-link">Contact us</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="https://socialresponsibility.carmax.com/"
                            class="footer-link">Social responsibility</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="https://foundation.carmax.com"
                            class="footer-link">CarMax Foundation</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="https://media.carmax.com" class="footer-link">Media center</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/supplier-inclusion" rel="nofollow" class="footer-link">Supplier inclusion</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="https://investors.carmax.com"
                            class="footer-link">Investor relations</a></li>
                </ul>
            </section>
            <section class="footer-primary-navigation-section">
                <h2 class="footer-primary-navigation-section-header">Careers</h2>
                <ul class="footer-primary-navigation-section-list">
                    <li class="footer-primary-navigation-section-list-item">
                        <a href="https://careers.carmax.com/us/en" class="footer-link">Search jobs</a>
                    </li>
                </ul>
            </section>
            <section class="footer-primary-navigation-section">
                <h2 class="footer-primary-navigation-section-header">More</h2>
                <ul class="footer-primary-navigation-section-list">
                    <li class="footer-primary-navigation-section-list-item"><a href="/service" class="footer-link">Service &amp; repairs</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/help-center" class="footer-link">FAQ &amp; support</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/why-carmax" class="footer-link">Why CarMax</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/buying-online" class="footer-link">Buying online</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/research" class="footer-link">Car research & advice</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/research/electric-vehicle-guide" class="footer-link">Guide to EVs</a></li>
                    <li class="footer-primary-navigation-section-list-item"><a href="/car-buying-process/maxcare-service-plans" class="footer-link">Warranties and MaxCare&#174;</a></li> 
                </ul>
            </section>
        </div>
        <section  class="footer-privacy">
            <span class="footer-privacy-text">
                By using carmax.com, you consent to the monitoring and storing of your interactions with the website,
                including with a CarMax vendor, for use in improving and personalizing our services. See our <a href="/privacy-policy" class="footer-link">Privacy Policy</a> for details.
            </span>
            <div class="footer-section-divider"></div>
        </section>
        
        <!-- Secondary navigation -->
        <section class="footer-secondary-navigation">
            <a href="/privacy-policy" class="footer-link">Privacy Policy</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/privacy-policy/opt-out" class="footer-link">Do Not Sell or Share My Information</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/home/docs/financial-privacy-policy-feb-2022.pdf" class="footer-link">Financial Privacy Policy</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/interest-based-ads" class="footer-link">Interest-Based Ads</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/terms" class="footer-link">Terms of Use</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/responsible-disclosure" class="footer-link">Responsible Disclosure</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/faq/safety-recalls" class="footer-link">CarMax Recall Policy</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/social-community-guidelines" class="footer-link">Social Community Guidelines</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/terms#ca-disclosure" class="footer-link">CA Supply Chain Transparency</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <a href="/notice-of-accessibility" class="footer-link">Accessibility</a>
            <span class="footer-secondary-navigation-divider">|</span>
            <span class="footer-link" id="oo-feedback-footer"></span>
        </section>
        <!-- Copyright -->
        <section class="footer-copyright">
            <span class="footer-copyright-text"
                >Copyright &copy; <span id="footer-copyright-year"></span> CarMax Enterprise Services, LLC</span
            >
        </section>
    </div>
</footer>
`;