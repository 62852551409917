import AutocompleteListMenu from '../menu/AutocompleteListMenu';
import { content } from '../../content';
import { QueryParameter, SearchResults, Results, UnavailableModelSuggestions, UnavailableModelSuggestion } from '../../types/search';

export default class SearchSuggestionsMenu extends AutocompleteListMenu {
    constructor(onMenuOpen?: () => any, onMenuClose?: () => any) {
        const inputEl = document.querySelector(
            `#${content.HEADER.id} #${content.HEADER_SEARCH.input.id}`
        ) as HTMLInputElement;
        const menuEl = document.querySelector(`#${content.HEADER.id} #${content.HEADER_SEARCH.menuId}`) as HTMLElement;
        const menuListEl = document.querySelector(
            `#${content.HEADER.id} #${content.HEADER_SEARCH.autocompleteList.id}`
        ) as HTMLElement;

        super(inputEl, menuEl, menuListEl, onMenuOpen, onMenuClose);
    }

    buildBaseListItem() {
        const listItemClassName = content.HEADER_SEARCH.autocompleteList.listItemClass;
        const listItemRole = content.HEADER_SEARCH.autocompleteList.listItemRole;
        const linkClassName = content.HEADER_SEARCH.autocompleteList.linkClass;
        const textClassName = content.HEADER_SEARCH.autocompleteList.textClass;
        const listItemLabel = document.createElement('div');
        const listItemEl = document.createElement('li');
        const link = document.createElement('a');
        listItemEl.setAttribute('role', listItemRole);
        listItemEl.className = listItemClassName;
        link.className = linkClassName;
        listItemLabel.className = textClassName;

        return { listItemEl, link, listItemLabel };
    }

    classifyResults(listItems: SearchResults) {
        const results: Results[] = (listItems.cognitiveResults && listItems.cognitiveResults.documentResult.results) || [];

        const classifiedResults: any = {
            articlesAndResearch: [],
            reviews: [],
            home: [],
            questionAndAnswer: {},
        };

        results.forEach((r) => {
            if (r.document.segment === 'research' || r.document.segment === 'articles') {
                classifiedResults.articlesAndResearch && classifiedResults.articlesAndResearch.push(r);
            }

            (classifiedResults[r.document.segment] || []).push(r);
        });

        if (listItems.cognitiveResults && listItems.cognitiveResults.answer) {
            classifiedResults.questionAndAnswer.query = listItems.cognitiveResults.query;
            classifiedResults.questionAndAnswer.answer = listItems.cognitiveResults.answer;
        }

        return classifiedResults;
    }

    buildSuggestions(listItems: SearchResults): HTMLElement {
        const { articlesAndResearch, reviews, home, questionAndAnswer } = this.classifyResults(listItems);
        let hasNoSavedSearches = false;
        let hasNoRecentSearches = false;
        let hasNoFreeTextSearches = false;
        let hasNoArticlesAndResearch = articlesAndResearch.length === 0;
        const savedSearches = listItems.savedSearches;
        const recentSearches = listItems.recentSearches;
        const freeTextSearches = listItems.freeTextSearches;

        if (savedSearches && savedSearches.length > 0) {
            const sectionId = 'savedSearches';
            const savedSearchesHtml = this.createMenuSection(sectionId, 'Saved Searches');
            const savedSearchesListHtml = savedSearchesHtml.querySelector(`#${sectionId}`);
            savedSearches.forEach((savedSearch) => {
                const listItems = this.buildSavedSearchListItem(savedSearch.displayText, savedSearch.secondaryText, savedSearch.entityId);
                savedSearchesHtml && savedSearchesListHtml && savedSearchesListHtml.appendChild(listItems);
            });
            this.menuEl && this.menuEl.appendChild(savedSearchesHtml);
        }
        else {
            hasNoSavedSearches = true;
        }

        if (recentSearches && recentSearches.length > 0) {
            const sectionId = 'recentSearches';
            const recentSearchesHtml = this.createMenuSection(sectionId, 'Recent Searches');
            const recentSearchesListHtml = recentSearchesHtml.querySelector(`#${sectionId}`);
            recentSearches.forEach((recentSearch) => {
                const listItems = this.buildRecentSearchListItem(recentSearch.secondaryText as string, recentSearch.value, recentSearch.additionalQueryStringParameters);
                recentSearchesHtml && recentSearchesListHtml && recentSearchesListHtml.appendChild(listItems);
            })
            this.menuEl && this.menuEl.appendChild(recentSearchesHtml);
        }
        else {
            hasNoRecentSearches = true;
        }

        if (freeTextSearches && freeTextSearches.length > 0) {
            const sectionId = 'suggestions';
            const suggestionsSearchesHtml = this.createMenuSection(sectionId, 'Search');
            const suggestionsSearchesListHtml = suggestionsSearchesHtml.querySelector(`#${sectionId}`);
            listItems.freeTextSearches.forEach((search) => {
                const listItems = this.buildFreeTextSearchListItem(search.displayText, search.value, search.type);
                suggestionsSearchesHtml && suggestionsSearchesListHtml && suggestionsSearchesListHtml.appendChild(listItems);
            });
            this.menuEl && this.menuEl.appendChild(suggestionsSearchesHtml);
        }
        else {
            hasNoFreeTextSearches = true;
        }

        if (hasNoSavedSearches && hasNoRecentSearches && hasNoFreeTextSearches && hasNoArticlesAndResearch) {
            const sectionId = 'optimizedSearch';
            const optimizedSearchHtml = this.createMenuSection(sectionId, 'Featured', false, true);
            const optimizedSearchListHtml = optimizedSearchHtml.querySelector(`#${sectionId}`);
            const SUVlistItem = this.buildSearchOptimizationListItemEl(content.OPTIMIZED_SEARCH.SUV.listItemUrl, content.OPTIMIZED_SEARCH.SUV.listItemText);
            optimizedSearchHtml && optimizedSearchListHtml && optimizedSearchListHtml.appendChild(SUVlistItem);
            const HybridListItem = this.buildSearchOptimizationListItemEl(content.OPTIMIZED_SEARCH.Hybrid.listItemUrl, content.OPTIMIZED_SEARCH.Hybrid.listItemText);
            optimizedSearchHtml && optimizedSearchListHtml && optimizedSearchListHtml.appendChild(HybridListItem);
            const TruckListItem = this.buildSearchOptimizationListItemEl(content.OPTIMIZED_SEARCH.Truck.listItemUrl, content.OPTIMIZED_SEARCH.Truck.listItemText);
            optimizedSearchHtml && optimizedSearchListHtml && optimizedSearchListHtml.appendChild(TruckListItem);
            const VansListItem = this.buildSearchOptimizationListItemEl(content.OPTIMIZED_SEARCH.Vans.listItemUrl, content.OPTIMIZED_SEARCH.Vans.listItemText);
            optimizedSearchHtml && optimizedSearchListHtml && optimizedSearchListHtml.appendChild(VansListItem);
            const ArticleListItem = this.buildSearchOptimizationListItemEl(content.OPTIMIZED_SEARCH.Article.listItemUrl, content.OPTIMIZED_SEARCH.Article.listItemText, true);
            optimizedSearchHtml && optimizedSearchListHtml && optimizedSearchListHtml.appendChild(ArticleListItem);
            this.menuEl && this.menuEl.append(optimizedSearchHtml);
        }

        if (articlesAndResearch && articlesAndResearch.length > 0) {
            const sectionId = 'articlesandresearch';
            const researchSearchesHtml = this.createMenuSection(sectionId, 'Research & Advice');
            const researchSearchesListHtml = researchSearchesHtml.querySelector(`#${sectionId}`);
            articlesAndResearch.forEach((search: Results) => {
                const listItemEl = this.buildFreeTextSearchListItemEl(search.highlights.title[0], search.document.url, 'Research & Advice');
                researchSearchesHtml && researchSearchesListHtml && researchSearchesListHtml.appendChild(listItemEl);
            });
            this.menuEl && this.menuEl.appendChild(researchSearchesHtml);
        }

        if (reviews && reviews.length > 0) {
            const sectionId = 'reviews';
            const reviewsSearchesHtml = this.createMenuSection(sectionId, 'Reviews');
            const reviewsSearchesListHtml = reviewsSearchesHtml.querySelector(`#${sectionId}`);
            reviews.forEach((search: Results) => {
                const listItemEl = this.buildFreeTextSearchListItemEl(search.highlights.title[0], search.document.url, 'Reviews');
                reviewsSearchesHtml && reviewsSearchesListHtml && reviewsSearchesListHtml.appendChild(listItemEl);
            });
            this.menuEl && this.menuEl.appendChild(reviewsSearchesHtml);
        }

        if (home && home.length > 0) {
            const sectionId = 'home';
            const homeSearchesHtml = this.createMenuSection(sectionId, 'Home');
            const homeSearchesListHtml = homeSearchesHtml.querySelector(`#${sectionId}`);
            home.forEach((search: Results) => {
                const listItemEl = this.buildFreeTextSearchListItemEl(search.highlights.title[0], search.document.url, 'Home');
                homeSearchesHtml && homeSearchesListHtml && homeSearchesListHtml.appendChild(listItemEl);
            });
            this.menuEl && this.menuEl.appendChild(homeSearchesHtml);
        }

        if (questionAndAnswer && questionAndAnswer.answer) {
            const sectionId = 'qa';
            const homeSearchesHtml = this.createMenuSection(sectionId, 'Q&A', true);
            const homeSearchesListHtml = homeSearchesHtml.querySelector(`#${sectionId}`);
            const listItemEl = this.buildQuestionAndAnswerListItemEl(questionAndAnswer);
            listItemEl.classList.add('qa-bg');
            homeSearchesHtml && homeSearchesListHtml && homeSearchesListHtml.appendChild(listItemEl);
            this.menuEl && this.menuEl.appendChild(homeSearchesHtml);
        }

        return this.menuEl;
    }

    buildUnavailableModelSuggestions(suggestion: UnavailableModelSuggestions): HTMLElement {
        const sectionId = 'unavailableModelSuggestions';
        const html = this.createMenuSection(sectionId, 'You Might Like', false, false, true);
        const sectionHtml = html.querySelector(`#${sectionId}`);
        suggestion.suggestions.forEach((s: UnavailableModelSuggestion) => {
            const listItemsHtml = this.buildUnavailableModelSuggestionListItemEl(s);

            html && sectionHtml && sectionHtml.appendChild(listItemsHtml);
        });

        const noteHtml = document.createElement('div');
        noteHtml.className = 'header-search-bar-note';
        noteHtml.innerHTML = "<span>We don&apos;t sell this car.</span>";
        this.menuEl && this.menuEl.appendChild(noteHtml);
        this.menuEl && this.menuEl.appendChild(html);
        return this.menuEl;
    }

    //This uses the legacy FreeTextSearches type
    buildFreeTextSearchListItemEl(listItemText: string, listItemLink: string, header: string): HTMLElement {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();
        const domParser = new DOMParser();
        const htmlDocument = domParser.parseFromString(`<div class='menu-item-inner-text'>${listItemText}</div>`, 'text/html').body;
        link.href = listItemLink;
        link.dataset.prop4 = listItemText;
        const prop5 = `Search Bar:${header}`;
        link.dataset.prop5 = prop5;
        link.setAttribute('data-kmx-analytics', `prop5=${prop5}`);
        link.appendChild(listItemLabel);
        listItemLabel.appendChild(htmlDocument.childNodes[0]);
        listItemEl.appendChild(link);
        return listItemEl;
    }

    buildQuestionAndAnswerListItemEl(questionAndAnswer: any): HTMLElement {
        const { listItemEl } = this.buildBaseListItem();
        const container = document.createElement('div');
        const questionClassName = 'list-item-question';
        const answerClassName = 'list-item-answer';

        const domParser = new DOMParser();
        const query = domParser.parseFromString(`<div class=${questionClassName}>${questionAndAnswer.query}</div>`, 'text/html').body.childNodes[0];
        const answer = domParser.parseFromString(`<div class=${answerClassName}>${questionAndAnswer.answer}</div>`, 'text/html').body.childNodes[0];

        container.className = 'questionContainer';
        container.appendChild(query);
        container.appendChild(answer);
        listItemEl.appendChild(container);

        return listItemEl;
    }

    //This uses the legacy SavedSearches type
    buildSavedSearchListItemEl(
        listItemName: string,
        listItemDesc: string,
        listItemLocation: string,
        listItemUrl: string
    ): HTMLElement {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();
        const description = document.createElement('div');
        const location = document.createElement('span');

        description.innerText = `${listItemDesc} `;
        description.className = 'list-item-description';
        location.textContent = `(${listItemLocation})`;
        description.appendChild(location);

        listItemLabel.textContent = listItemName;
        listItemLabel.classList.add('saved-search-label');
        link.href = listItemUrl;
        link.dataset.prop4 = listItemName;
        link.setAttribute('data-kmx-analytics', 'prop5=Search Bar:Saved Searches');
        link.appendChild(listItemLabel);
        link.appendChild(description);
        location.className = 'list-item-location';

        listItemEl.appendChild(link);

        return listItemEl;
    }

    buildSavedSearchListItem(displayText: string, secondaryText: string | undefined, entityId: string | undefined) {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();
        const description = document.createElement('div');
        description.innerText = `${secondaryText} `;
        description.className = 'list-item-description';

        const filterCount = document.createElement('span');
        filterCount.className = 'filters';

        listItemEl.classList.add('saved-search');
        link.href = `/cars?savedSearchId=${entityId}`;
        link.dataset.prop4 = displayText;
        link.setAttribute('data-kmx-analytics', 'prop5=Search Bar:Saved Searches');

        link.appendChild(listItemLabel);
        link.appendChild(description);
        link.appendChild(filterCount);
        listItemLabel.textContent = displayText;
        listItemEl.appendChild(link);
        return listItemEl;
    }

    buildRecentSearchListItem(displayText: string, searchToken: string, additionalQueryStringParameters?: QueryParameter[]) {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();

        const filterCount = document.createElement('span');
        filterCount.className = 'filters';

        listItemEl.classList.add('recent-search');
        link.dataset.prop4 = displayText;
        link.setAttribute('data-kmx-analytics', 'prop5=Search Bar:Recent Searches');

        if (additionalQueryStringParameters) {
            link.href = this.buildRecentSearchUrlWithAdditionalParameters(searchToken, additionalQueryStringParameters);
        }
        else {
            if (displayText == "My Budget") {
                link.href = searchToken;
            }
            else
            {
                link.href = `/cars?searchToken=${searchToken}`;
            }
        }

        link.appendChild(listItemLabel);
        link.appendChild(filterCount);
        listItemLabel.textContent = displayText;
        listItemEl.appendChild(link);
        return listItemEl;
    }

    buildRecentSearchUrlWithAdditionalParameters(searchToken: string, params: QueryParameter[]) {
        let queryString = "";

        for(var param of params) {
            if (param.type == "ConditionAmountMax") {
                queryString += `&conditionedAmount=0-${param.content}`
            }
        }

        return `/cars?searchToken=${searchToken}${queryString}`;
    }

    buildFreeTextSearchListItem(displayText: string, value: string, type?: string) {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();

        const filterCount = document.createElement('span');
        filterCount.className = 'filters';

        if (type === 'Url') {
            // Fully resolved url.
            link.href = value;
        } else {
            var queryString = value?.replace(/ /g, '+');
            link.href = `/cars?search=${queryString}`;
        }

        link.dataset.prop4 = displayText;
        link.setAttribute('data-kmx-analytics', 'prop5=Search Bar:Search');

        link.appendChild(listItemLabel);
        link.appendChild(filterCount);
        listItemLabel.textContent = displayText;
        listItemEl.appendChild(link);
        return listItemEl;
    }

    //This uses RecentSearchesFromLocalStorage type
    buildRecentSearchListItemEl(
        originalSearch: string,
        listItemText: string,
        listItemFilters: number,
        listItemUrl: string
    ): HTMLElement {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();
        const filterCount = document.createElement('span');
        filterCount.className = 'filters';
        if (listItemFilters > 0) {
            filterCount.innerHTML = `+${listItemFilters}${listItemFilters > 1 ? ' filters' : ' filter'}`;
        }
        listItemEl.classList.add('recent-search');
        link.href = listItemUrl;
        link.dataset.prop4 = listItemText;
        link.setAttribute('data-kmx-analytics', 'prop5=Search Bar:Recent Searches');

        if (originalSearch && originalSearch.localeCompare(listItemText, undefined, { sensitivity: 'accent' })) {
            const greedySearch = document.createElement('span');
            greedySearch.innerHTML = `${originalSearch} ${String.fromCharCode(8594)} &nbsp`;
            link.appendChild(greedySearch);
        }

        link.appendChild(listItemLabel);
        link.appendChild(filterCount);
        listItemLabel.textContent = `${listItemText} `;
        listItemEl.appendChild(link);
        return listItemEl;
    }

    buildUnavailableModelSuggestionListItemEl(
        suggestion: UnavailableModelSuggestion
    ): HTMLElement {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();
        link.href = suggestion.url;
        link.dataset.prop4 = suggestion.text;
        const prop5 = `Search Bar:You Might Like`;
        link.dataset.prop5 = prop5;
        link.setAttribute('data-kmx-analytics', `prop5=${prop5}`);

        link.appendChild(listItemLabel);
        listItemLabel.textContent = suggestion.text;
        listItemEl.appendChild(link);

        return listItemEl;
    }

    buildSearchOptimizationListItemEl(
        listItemUrl: string,
        listItemText: string,
        isArticleItem: boolean = false,
    ): HTMLElement {
        const { listItemEl, link, listItemLabel } = this.buildBaseListItem();

        link.href = listItemUrl;
        link.className = `menu-item-link optimized-link`;
        link.dataset.prop4 = listItemText;
        link.setAttribute('data-kmx-analytics', 'prop5=Search Bar:Suggestions');
        link.appendChild(listItemLabel);
        if (isArticleItem) {
            listItemLabel.innerHTML = `Most Reliable Cars &#8212; <span class="optimized-article">Article</span>`;
        }
        else {
            listItemLabel.textContent = listItemText;
        }
        listItemEl.className = `header-footer-menu-list-item optimized-list-item`
        listItemEl.appendChild(link);

        return listItemEl;
    }

    createMenuSection(listId: string, headingTitle: string, isQuestionAndAnswer: boolean = false, isFeatured: boolean = false, hasNote: boolean = false) {
        // Create the elements needed for the search menu list
        const searchListContainer = document.createElement('div');
        const heading = document.createElement('div');
        const searchList = document.createElement('ul');
        const listRole = content.HEADER_SEARCH.autocompleteList.listRole;

        //Apply the classes and id to the specific list
        searchList.className = `header-footer-menu-list ${isQuestionAndAnswer && 'qa-bg'}`;
        searchList.id = listId;
        searchList.setAttribute('role', listRole);
        searchListContainer.className = `header-footer-menu-section ${isQuestionAndAnswer && 'qa-section'}  ${hasNote ? 'has-note' : ''}`;
        heading.className = `header-footer-menu-heading ${isQuestionAndAnswer && 'qa-heading'} ${isFeatured && 'optimized-heading'}`;
        heading.innerHTML = headingTitle;

        // build html structure and add it the menu
        searchListContainer.appendChild(heading);
        searchListContainer.appendChild(searchList);
        return searchListContainer;
    }
}
