import * as api from '../../api/api';
import { Options } from '../../api/api';
import { icons } from '../../webcomponents/exposed-nav/icons';
import { getMyKmxUserId } from '../../storage/cookie';
import { getVehicleHeroImage } from './images';

export const getReservation = async () => {
    const myKmxUserId = getMyKmxUserId();
    const { origin } = window.location;
    const rsvpUrl = origin + `/mycarmax/api/header/${myKmxUserId}/reservation`; // MyCarMax BFF

    const options: Options = {
        url: rsvpUrl,
    };
    api.get(
        options,
        function success(res) {
            const icon = res?.stockNumber ? getVehicleHeroImage(res.stockNumber) : icons.houseUnfilledNonBtoa;
            const text = res?.stockNumber ? 'My cart' : 'My dashboard';
            setIconContent(icon, text);

            requestAnimationFrame(() => {
                clearLoader(); // TODO: clearLoader gets executed after the api call resolves but before the image finishes painting
            });
        },
        function error(e) {
            clearLoader();
            const icon = icons.houseUnfilledNonBtoa;
            const text = 'My dashboard';

            setIconContent(icon, text);

            console.error('Error fetching rsvp data: ', e);
        }
    );
};

export const setIconContent = (icon: string, text: string) => {
    const iconContainer = document.querySelector('#my-dashboard-link > div') as HTMLElement;
    const iconNode = document.createRange().createContextualFragment(icon);
    const iconLabel = document.querySelector('#my-dashboard-link > span') as HTMLElement;

    iconContainer.appendChild(iconNode);
    iconLabel.innerText = text;
}

export const clearLoader = () => {
    const iconContainer = document.getElementById('reservation-loader') as HTMLElement;
    iconContainer.style.border = 'transparent';
}
